import { useTheme } from "@emotion/react"
import { AppBar, Avatar, Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"

interface TabPanelProps {
	children?: React.ReactNode
	dir?: string
	index: number
	value: number
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	}
}

export const Home = () => {
	const theme = useTheme()
	const [value, setValue] = useState(0)

	const { qr } = useParams()

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}
	return (
		<>
			<Paper sx={{ mb: 4 }}>
				<Box>
					<img src="./backgroundspominko.jpeg" style={{ width: "100%", minHeight: 200, objectFit: "cover" }}></img>
				</Box>
				<Box display="flex" gap={2} marginTop={-10} padding={2} flexWrap="wrap">
					<Avatar sx={{ width: 120, height: 120 }}></Avatar>
					<Stack direction="column" justifyContent="flex-end">
						<Typography>Ime Priimek</Typography>
						<Typography>Datum od do</Typography>
					</Stack>
				</Box>
			</Paper>
			<Paper sx={{ p: 4 }}>
				<Box>
					<Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth" aria-label="full width tabs example">
						<Tab label="Bio" {...a11yProps(0)} />
						<Tab label="Slike" {...a11yProps(1)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						Bio še ni podatkov TEST
					</TabPanel>
					<TabPanel value={value} index={1}>
						Slike
					</TabPanel>
				</Box>
			</Paper>
		</>
	)
}
