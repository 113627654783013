import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
      </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const Check = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { qr } = useParams<{ qr: string }>(); // Capture the dynamic part of the URL (qr-800)
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Function to fetch data from the backend API
  const fetchData = async () => {
    try {
      // Extract the number after 'qr-' from the qr param
      const qrId = qr?.split("qr-")[1];

      if (!qrId) {
        throw new Error("Invalid QR code in the URL");
      }

      // Send the extracted QR code to your backend API
      const response = await axios.get(
        `https://api.spominko.si/api/v1/qr/${qrId}`
      );

      const data = response.data;
      if (data && data.length > 0) {
        // If data is returned, perform a full-page redirect to the home page
        console.log("test");
      } else {
        // If no data is returned, perform a full-page redirect to the register page
        window.location.href = `https://app.spominko.si/register/${qrId}`;
      }
    } catch (err) {
      setError("Error fetching data from the API");
      setLoading(false);
      // On error, perform a full-page redirect to the register page
      window.location.href = "https://app.spominko.si/register";
    }
  };

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, [qr]); // Fetch data whenever 'qr' param changes

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // If data is still loading, show a loading message
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  // If there was an error in the API call, show an error message
  if (error) {
    return <Typography>{error}</Typography>;
  }

  // If the user somehow still remains on this page, return an empty fragment.
  return <></>;
};
