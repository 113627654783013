import { useState } from "react";
import { Box, TextField, Button, Typography, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";

export const Register = () => {
  // States to control password visibility, values, and validation
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { qr } = useParams<{ qr: string }>();
  
  // Toggles to show or hide passwords
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

  // Handlers for password field changes
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage(""); // Reset error when user starts typing
  };

  const handleMailChange = (e) => {
    setMail(e.target.value);
    setErrorMessage(""); // Reset error when user starts typing
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
    setErrorMessage(""); // Reset error when user starts typing
  };

  // Register button click handler
  const handleRegister = async() => {
    if (password !== repeatPassword) {
      setErrorMessage("Passwords do not match.");
    } else {
      // Success: Handle registration logic (e.g., submit form)
      console.log("Successfully Registered");
      await axios.get(
        `https://api.spominko.si/api/v1/qr/sendmail/${qr}/${mail}`
      );
      await axios.get(
        `https://api.spominko.si/api/v1/qr/prijava/${qr}/${mail}/${password}`
      );

      window.location.href = "https://app.spominko.si/mail";
      setErrorMessage(""); // Clear any existing error
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh", // Full screen height
      }}
    >
      {/* Left side (Black with Image in the center) */}
      <Box
        sx={{
          xs:"none",
          backgroundColor: "black",
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/slikapngtest1.png"
          alt="Test Image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>

      {/* Right side (Form) */}
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%", // Fill width
            maxWidth: "400px", // Optional: Set max-width for form
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem", // Spacing between form elements
          }}
        >
          <Typography variant="h4" align="center">
            Registracija spominko QR-kode
          </Typography>

          {/* E-mail */}
          <TextField
            label="E-mail"
            variant="outlined"
            value={mail}
            onChange={handleMailChange}
            fullWidth
            required
          />

          {/* Password */}
          <TextField
            label="Geslo"
            variant="outlined"
            type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
            fullWidth
            required
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Repeat Password */}
          <TextField
            label="Ponovi geslo"
            variant="outlined"
            type={showRepeatPassword ? "text" : "password"} // Toggle between "text" and "password"
            fullWidth
            required
            value={repeatPassword}
            onChange={handleRepeatPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle repeat password visibility"
                    onClick={handleClickShowRepeatPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Error Message */}
          {errorMessage && (
            <Typography color="error" variant="body2" align="center">
              {errorMessage}
            </Typography>
          )}

          {/* Register Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "black",
              color: "white",
              '&:hover': {
                backgroundColor: "darkgray", // Optional: Change color on hover
              },
            }}
            disabled={!password || !repeatPassword || password !== repeatPassword}
            onClick={handleRegister} // Disable button if passwords don't match
          >
            Registriraj
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
