import { Route, Routes } from "react-router-dom";
import { Home, Login, Register, Check, Mail} from "./pages";
import { Box } from "@mui/material";
import { MainLayout } from "./layout/MainLayout";

export const App = () => {
  return (
    <Box>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="home/:qr" element={<Check />} />
        </Route>
        <Route path="register/:qr" element={<Register />} />
        <Route path="mail" element={<Mail />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Box>
  );
};
