import { Box, Typography } from "@mui/material";

export const Mail = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh", // Full screen height
      }}
    >
      {/* Left side (Black with Image in the center) */}
      <Box
        sx={{
          xs:"none",
          backgroundColor: "black",
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/slikapngtest1.png"
          alt="Test Image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>

      {/* Right side (Form) */}
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%", // Fill width
            maxWidth: "400px", // Optional: Set max-width for form
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem", // Spacing between form elements
          }}
        >
          <Typography variant="h6" align="center">
          Na vaš E-mail naslov smo vam poslali aktivacijsko povezavo. S klikom na
          gumb "Aktivacija spominske ploščice" aktivirate svojo spominsko stran.
          </Typography>
                
        </Box>
      </Box>
    </Box>
  );
};
